import React from 'react'

var SingleBrand = ({ url }) => <>
    {/* start client item */}
    <div className="col text-center sm-mb-30px">
        <a href="#">
            <img src={url || "images/logo-asos.svg"} className="h-30px" alt="" />
        </a>
    </div>
    {/* end client item */}
</>

export default function HomePageBrands() {
    var brands = [
        "images/logo-asos.svg",
        "images/logo-chanel.svg",
        "images/logo-gucci.svg",
        "images/logo-celine.svg",
        "images/logo-adidas.svg"
    ]
    return <>
        {/* start section */}
        <section className="half-section border-bottom border-color-extra-medium-gray">
            <div className="container">
                <div
                    className="row row-cols-2 row-cols-md-5 row-cols-sm-3 position-relative justify-content-center"
                    data-anime='{ "el": "childs", "translateY": [-15, 0], "scale": [0.8, 1], "opacity": [0,1], "duration": 300, "delay": 0, "staggervalue": 100, "easing": "easeOutQuad" }'
                >
                    {brands.map(brand => <SingleBrand url={brand} />)}

                </div>
            </div>
        </section>
        {/* end section */}
    </>
}
