// ! Burayı komponente çevirme js çalışmıyor
import React from 'react'

export default function ScrollingTexts() {
    return <>
        {/* start section */}
        <section className="p-0 border-top border-bottom border-color-extra-medium-gray">
            <div className="container-fluid">
                <div className="row position-relative">
                    <div
                        className="col swiper text-center swiper-width-auto"
                        data-slider-options='{ "slidesPerView": "auto", "spaceBetween":0, "speed": 10000, "loop": true, "pagination": { "el": ".slider-four-slide-pagination-2", "clickable": false }, "allowTouchMove": false, "autoplay": { "delay":0, "disableOnInteraction": false }, "navigation": { "nextEl": ".slider-four-slide-next-2", "prevEl": ".slider-four-slide-prev-2" }, "keyboard": { "enabled": true, "onlyInViewport": true }, "effect": "slide" }'
                    >
                        <div className="swiper-wrapper marquee-slide">
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    Get 20% off for your first order
                                </div>
                            </div>
                            {/* end client item */}
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    The fashion core collection
                                </div>
                            </div>
                            {/* end client item */}
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    100% secure protected payment
                                </div>
                            </div>
                            {/* end client item */}
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    Free shipping for orders over $130
                                </div>
                            </div>
                            {/* end client item */}
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    Pay with multiple credit cards
                                </div>
                            </div>
                            {/* end client item */}
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    Get 20% off for your first order
                                </div>
                            </div>
                            {/* end client item */}
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    The fashion core collection
                                </div>
                            </div>
                            {/* end client item */}
                            {/* start client item */}
                            <div className="swiper-slide">
                                <div className="alt-font fs-26 fw-500 text-dark-gray border-color-extra-medium-gray border-end pt-30px pb-30px ps-60px pe-60px sm-p-25px">
                                    100% secure protected payment
                                </div>
                            </div>
                            {/* end client item */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end section */}
    </>
}
