import React from 'react'

export default function MnkHeader() {
    return <>
        {/* start header */}
        <header className="header-with-topbar">
            {/* start header top bar */}
            <div className="header-top-bar top-bar-light bg-base-color disable-fixed md-border-bottom border-color-transparent-dark-very-light">
                <div className="container-fluid">
                    <div className="row h-40px align-items-center m-0">
                        <div className="col-12 justify-content-center alt-font fs-13 fw-500 text-uppercase">
                            <div className="text-dark-gray">
                                300₺ üzeri siparişlerde STANDART Kargo ÜCRETSİZ.
                            </div>
                            <a
                                href="#"
                                className="text-dark-gray fw-600 ms-5px text-dark-gray-hover"
                            >
                                <span className="text-decoration-line-bottom">Şimdi alışveriş yapın!</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* end header top bar */}
            {/* start navigation */}
            <nav className="navbar navbar-expand-lg header-light bg-white disable-fixed center-logo">
                <div className="container-fluid">
                    <div className="col-auto col-xxl-3 col-lg-2 menu-logo">
                        <div className="header-icon d-none d-lg-flex">
                            <div className="widget-text icon alt-font">
                                <a href="demo-fashion-store-contact.html">
                                    <i className="feather icon-feather-map-pin d-inline-block me-5px" />
                                    <span className="d-none d-xxl-inline-block">Mağazayı bul</span>
                                </a>
                            </div>
                            <div className="widget-text icon alt-font">
                                <a href="https://www.instagram.com/" target="_blank">
                                    <i className="feather icon-feather-instagram d-inline-block me-5px" />
                                    <span className="d-none d-xxl-inline-block">
                                        100k Takipçi
                                    </span>
                                </a>
                            </div>
                        </div>
                        <a className="navbar-brand" href="demo-fashion-store.html">
                            <h3 style={{ margin: 0 }}>Minikka</h3>
                            {/*
                  <img src="images/demo-fashion-store-logo-black.png"
                  data-at2x="images/demo-fashion-store-logo-black@2x.png" alt="" class="default-logo">
                  <img src="images/demo-fashion-store-logo-black.png"
                  data-at2x="images/demo-fashion-store-logo-black@2x.png" alt="" class="alt-logo">
                  <img src="images/demo-fashion-store-logo-black.png"
                  data-at2x="images/demo-fashion-store-logo-black@2x.png" alt="" class="mobile-logo">
                  */}
                        </a>
                    </div>
                    <div className="col-auto col-xxl-6 col-lg-8 menu-order">
                        <button
                            className="navbar-toggler float-end"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-line" />
                            <span className="navbar-toggler-line" />
                            <span className="navbar-toggler-line" />
                            <span className="navbar-toggler-line" />
                        </button>
                        <div
                            className="collapse navbar-collapse justify-content-between"
                            id="navbarNav"
                        >
                            <ul className="navbar-nav alt-font navbar-left justify-content-end">
                                <li className="nav-item">
                                    <a href="demo-fashion-store.html" className="nav-link">
                                        Anasayfa
                                    </a>
                                </li>
                                <li className="nav-item dropdown submenu">
                                    <a href="demo-fashion-store-shop.html" className="nav-link">
                                        Mağaza
                                    </a>
                                    <i
                                        className="fa-solid fa-angle-down dropdown-toggle"
                                        id="navbarDropdownMenuLink1"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    />
                                    <div
                                        className="dropdown-menu submenu-content"
                                        aria-labelledby="navbarDropdownMenuLink1"
                                    >
                                        <div className="d-lg-flex mega-menu m-auto flex-column">
                                            <div className="row row-cols-1 row-cols-lg-5 row-cols-md-3 row-cols-sm-3 mb-50px md-mb-25px xs-mb-15px">
                                                <div className="col">
                                                    <ul>
                                                        <li className="sub-title">Men</li>
                                                        <li>
                                                            <a href="#">Jeans</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Trousers</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Swimwear</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Casual shirts</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Rain jackets</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Loungewear</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col">
                                                    <ul>
                                                        <li className="sub-title">Women</li>
                                                        <li>
                                                            <a href="#">Dupattas</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Leggings</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Ethnic wear</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Kurtas &amp; suits</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Western wear</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Dress materials</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col">
                                                    <ul>
                                                        <li className="sub-title">Kids</li>
                                                        <li>
                                                            <a href="#">Dresses</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Jumpsuits</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Track pants</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Ethnic wear</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Value packs</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Loungewear</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col">
                                                    <ul>
                                                        <li className="sub-title">Divided</li>
                                                        <li>
                                                            <a href="#">Tops</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Dresses</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Shorts</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Swimwear</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Jeans</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Jackets</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col">
                                                    <ul>
                                                        <li className="sub-title">Accessories</li>
                                                        <li>
                                                            <a href="#">Shoes</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Scarves</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Watches</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Wristwear</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Backpacks</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Sunglasses</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row row-cols-1 row-cols-sm-2">
                                                <div className="col">
                                                    <a href="demo-fashion-store-shop.html">
                                                        <img
                                                            src="https://via.placeholder.com/580x175"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col">
                                                    <a href="demo-fashion-store-shop.html">
                                                        <img
                                                            src="https://via.placeholder.com/580x175"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown submenu">
                                    <a
                                        href="demo-fashion-store-collection.html"
                                        className="nav-link"
                                    >
                                        Koleksiyonlar
                                    </a>
                                    <i
                                        className="fa-solid fa-angle-down dropdown-toggle"
                                        id="navbarDropdownMenuLink2"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    />
                                    <div
                                        className="dropdown-menu submenu-content"
                                        aria-labelledby="navbarDropdownMenuLink2"
                                    >
                                        <div className="d-lg-flex mega-menu m-auto flex-column">
                                            <div className="row row-cols-2 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 md-mx-0 align-items-center justify-content-center">
                                                <div className="col md-mb-25px">
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="justify-content-center mb-10px"
                                                    >
                                                        <img
                                                            src="https://via.placeholder.com/190x140"
                                                            className="border-radius-4px w-100"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="btn btn-hover-animation fw-500 text-uppercase-inherit justify-content-center pt-0 pb-0"
                                                    >
                                                        <span>
                                                            <span className="btn-text text-dark-gray fs-17">
                                                                Polo t-shirts
                                                            </span>
                                                            <span className="btn-icon">
                                                                <i className="fa-solid fa-arrow-right icon-very-small w-auto" />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col md-mb-25px">
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="justify-content-center mb-10px"
                                                    >
                                                        <img
                                                            src="https://via.placeholder.com/190x140"
                                                            className="border-radius-4px w-100"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="btn btn-hover-animation fw-500 text-uppercase-inherit justify-content-center pt-0 pb-0"
                                                    >
                                                        <span>
                                                            <span className="btn-text text-dark-gray fs-17">
                                                                Sunglasses
                                                            </span>
                                                            <span className="btn-icon">
                                                                <i className="fa-solid fa-arrow-right icon-very-small w-auto" />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col md-mb-25px">
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="justify-content-center mb-10px"
                                                    >
                                                        <img
                                                            src="https://via.placeholder.com/190x140"
                                                            className="border-radius-4px w-100"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="btn btn-hover-animation fw-500 text-uppercase-inherit justify-content-center pt-0 pb-0"
                                                    >
                                                        <span>
                                                            <span className="btn-text text-dark-gray fs-17">
                                                                Skinny blazer
                                                            </span>
                                                            <span className="btn-icon">
                                                                <i className="fa-solid fa-arrow-right icon-very-small w-auto" />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col sm-mb-25px">
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="justify-content-center mb-10px"
                                                    >
                                                        <img
                                                            src="https://via.placeholder.com/190x140"
                                                            className="border-radius-4px w-100"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="btn btn-hover-animation fw-500 text-uppercase-inherit justify-content-center pt-0 pb-0"
                                                    >
                                                        <span>
                                                            <span className="btn-text text-dark-gray fs-17">
                                                                Casual shoes
                                                            </span>
                                                            <span className="btn-icon">
                                                                <i className="fa-solid fa-arrow-right icon-very-small w-auto" />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col">
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="justify-content-center mb-10px"
                                                    >
                                                        <img
                                                            src="https://via.placeholder.com/190x140"
                                                            className="border-radius-4px w-100"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="btn btn-hover-animation fw-500 text-uppercase-inherit justify-content-center pt-0 pb-0"
                                                    >
                                                        <span>
                                                            <span className="btn-text text-dark-gray fs-17">
                                                                Winter jackets
                                                            </span>
                                                            <span className="btn-icon">
                                                                <i className="fa-solid fa-arrow-right icon-very-small w-auto" />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col">
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="justify-content-center mb-10px"
                                                    >
                                                        <img
                                                            src="https://via.placeholder.com/190x140"
                                                            className="border-radius-4px w-100"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <a
                                                        href="demo-fashion-store-collection.html"
                                                        className="btn btn-hover-animation fw-500 text-uppercase-inherit justify-content-center pt-0 pb-0"
                                                    >
                                                        <span>
                                                            <span className="btn-text text-dark-gray fs-17">
                                                                Men's shorts
                                                            </span>
                                                            <span className="btn-icon">
                                                                <i className="fa-solid fa-arrow-right icon-very-small w-auto" />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="navbar-nav alt-font navbar-right justify-content-start">
                                <li className="nav-item">
                                    <a href="demo-fashion-store-magazine.html" className="nav-link">
                                        Blog
                                    </a>
                                </li>
                                <li className="nav-item dropdown simple-dropdown">
                                    <a href="javascript:void(0);" className="nav-link">
                                        Sayfalar
                                    </a>
                                    <i
                                        className="fa-solid fa-angle-down dropdown-toggle"
                                        id="navbarDropdownMenuLink3"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    />
                                    <ul
                                        className="dropdown-menu"
                                        aria-labelledby="navbarDropdownMenuLink3"
                                    >
                                        <li>
                                            <a href="demo-fashion-store-about.html">Hakkımızda</a>
                                        </li>
                                        <li>
                                            <a href="demo-fashion-store-faq.html">S.S.S.</a>
                                        </li>
                                        <li>
                                            <a href="demo-fashion-store-wishlist.html">İstek Listesi</a>
                                        </li>
                                        <li>
                                            <a href="demo-fashion-store-account.html">Hesap</a>
                                        </li>
                                        <li>
                                            <a href="demo-fashion-store-cart.html">Sepet</a>
                                        </li>
                                        <li>
                                            <a href="demo-fashion-store-checkout.html">Checkout</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a href="demo-fashion-store-contact.html" className="nav-link">
                                        İletişim
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-auto col-xxl-3 col-lg-2 text-end">
                        <div className="header-icon">
                            <div className="header-search-icon icon alt-font">
                                <a
                                    href="javascript:void(0)"
                                    className="search-form-icon header-search-form"
                                >
                                    <i className="feather icon-feather-search me-5px" />
                                    <span className="d-none d-xxl-inline-block">Ara</span>
                                </a>
                                <div className="search-form-wrapper">
                                    <button
                                        title="Close"
                                        type="button"
                                        className="search-close alt-font"
                                    >
                                        ×
                                    </button>
                                    <form
                                        id="search-form"
                                        role="search"
                                        method="get"
                                        className="search-form text-left"
                                        action="search-result.html"
                                    >
                                        <div className="search-form-box">
                                            <h2 className="text-dark-gray text-center mb-4 fw-600 alt-font ls-minus-1px">
                                                What are you looking for?
                                            </h2>
                                            <input
                                                className="search-input alt-font"
                                                id="search-form-input5e219ef164995"
                                                placeholder="Enter your keywords..."
                                                name="s"
                                                defaultValue=""
                                                type="text"
                                                autoComplete="off"
                                            />
                                            <button type="submit" className="search-button">
                                                <i
                                                    className="feather icon-feather-search"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="widget-text icon alt-font">
                                <a href="demo-fashion-store-account.html">
                                    <i className="feather icon-feather-user d-inline-block me-5px" />
                                    <span className="d-none d-xxl-inline-block">Hesap</span>
                                </a>
                            </div>
                            <div className="header-cart-icon icon">
                                <div className="header-cart dropdown">
                                    <a href="javascript:void(0);">
                                        <i className="feather icon-feather-shopping-bag" />
                                        <span className="cart-count alt-font text-white bg-dark-gray">
                                            2
                                        </span>
                                    </a>
                                    <ul className="cart-item-list">
                                        <li className="cart-item align-items-center">
                                            <a href="javascript:void(0);" className="alt-font close">
                                                ×
                                            </a>
                                            <div className="product-image">
                                                <a href="demo-fashion-store-single-product.html">
                                                    <img
                                                        src="https://via.placeholder.com/600x765"
                                                        className="cart-thumb"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-detail fw-600">
                                                <a href="demo-fashion-store-single-product.html">
                                                    Ribbed tank
                                                </a>
                                                <span className="item-ammount fw-400">1 x $23.00</span>
                                            </div>
                                        </li>
                                        <li className="cart-item align-items-center">
                                            <a href="javascript:void(0);" className="alt-font close">
                                                ×
                                            </a>
                                            <div className="product-image">
                                                <a href="demo-fashion-store-single-product.html">
                                                    <img
                                                        src="https://via.placeholder.com/600x765"
                                                        className="cart-thumb"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-detail fw-600">
                                                <a href="demo-fashion-store-single-product.html">
                                                    Pleated dress
                                                </a>
                                                <span className="item-ammount fw-400">2 x $15.00</span>
                                            </div>
                                        </li>
                                        <li className="cart-total">
                                            <div className="fs-18 alt-font mb-15px">
                                                <span className="w-50 fw-500 text-start">Subtotal:</span>
                                                <span className="w-50 text-end fw-700">$199.99</span>
                                            </div>
                                            <a
                                                href="demo-fashion-store-cart.html"
                                                className="btn btn-large btn-transparent-light-gray border-color-extra-medium-gray"
                                            >
                                                View cart
                                            </a>
                                            <a
                                                href="demo-fashion-store-checkout.html"
                                                className="btn btn-large btn-dark-gray btn-box-shadow"
                                            >
                                                Checkout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        {/* end header */}
    </>
}
