import React from 'react'
import ShopItem from './ShopItem'

export default function BestSellerProducts() {
    var itemInfos = [
        { name: "Çocuk Botu", type: "hot", price: 2350, previousPrice: 2500 },
        { name: "Çocuk Sandaleti", type: "new", price: 1800},
        { name: "Çocuk Spor Ayakkabısı", type: "hot", price: 2100, previousPrice: 2200 },
        { name: "Çocuk Terlik", type: null, price: 1500},
        { name: "Çocuk Günlük Ayakkabı", type: "new", price: 1900, previousPrice: 2000 },
        { name: "Çocuk Bağcıklı Ayakkabı", type: "new", price: 2200},
        { name: "Çocuk Casual Ayakkabı", type: null, price: 1750, previousPrice: 1800 },
        { name: "Çocuk Yazlık Ayakkabı", type: "hot", price: 1950, previousPrice: 2100 },
        { name: "Çocuk Rahat Ayakkabı", type: null, price: 2000},
        { name: "Çocuk Kışlık Ayakkabı", type: "new", price: 2400, previousPrice: 2500 }
    ]
    return <>
        {/* start section */}
        <section className="ps-7 pe-7 pb-3 lg-ps-3 lg-pe-3 sm-pb-6 xs-px-0">
            <div className="container">
                <div className="row mb-5 xs-mb-8">
                    <div className="col-12 text-center">
                        <h2 className="alt-font text-dark-gray mb-0 ls-minus-2px">
                            Çok Satan {" "}
                            <span className="text-highlight fw-600">
                                ürünler
                                <span className="bg-base-color h-5px bottom-2px" />
                            </span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <ul
                            className="shop-modern shop-wrapper grid-loading grid grid-5col lg-grid-4col md-grid-3col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                            data-anime='{ "el": "childs", "translateY": [-15, 0], "opacity": [0,1], "duration": 300, "delay": 0, "staggervalue": 100, "easing": "easeOutQuad" }'
                        >
                            <li className="grid-sizer" />
                            {itemInfos.map(itemInfo => <ShopItem itemInfo={itemInfo} />)}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/* end section */}
    </>
}
