import React from 'react'

export default function ShopItem({ itemInfo }) {
    return <>
        {/* start shop item */}
        <li className="grid-item">
            <div className="shop-box mb-10px">
                <div className="shop-image mb-20px">
                    <a href="demo-fashion-store-single-product.html">
                        <img src={itemInfo?.picture || "https://via.placeholder.com/600x765"} alt="" />
                        {{
                            new: <span className="lable new">Yeni</span>,
                            hot: <span className="lable hot">Hızlı</span>
                        }[itemInfo?.type]}
                        <div className="shop-overlay bg-gradient-gray-light-dark-transparent" />
                    </a>
                    <div className="shop-buttons-wrap">
                        <a
                            href="demo-fashion-store-single-product.html"
                            className="alt-font btn btn-small btn-box-shadow btn-white btn-round-edge left-icon add-to-cart"
                        >
                            <i className="feather icon-feather-shopping-bag" />
                            <span className="quick-view-text button-text">
                                Sepete ekle
                            </span>
                        </a>
                    </div>
                    <div className="shop-hover d-flex justify-content-center">
                        <ul>
                            <li>
                                <a
                                    href="#"
                                    className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="İstek listesine ekle"
                                >
                                    <i className="feather icon-feather-heart fs-16" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Hızlı alışveriş"
                                >
                                    <i className="feather icon-feather-eye fs-16" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="shop-footer text-center">
                    <a
                        href="demo-fashion-store-single-product.html"
                        className="alt-font text-dark-gray fs-19 fw-500"
                    >
                        {itemInfo?.name || "no name"}
                    </a>
                    <div className="price lh-22 fs-16">
                        {itemInfo?.previousPrice && <del>{itemInfo?.previousPrice}₺</del>}{itemInfo?.price || 0}₺
                    </div>
                </div>
            </div>
        </li>
        {/* end shop item */}
    </>
}
