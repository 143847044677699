import React from 'react'

export default function DiscountBanner() {
    return <>
        {/* start section */}
        <section className="p-15px bg-dark-gray text-white">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <span className="fs-15 text-uppercase fw-500">
                            Favori ayakkabı modellerimizde ekstra %25 indirim. Kodu kullanın:
                            <span className="fs-14 fw-700 lh-28 alt-font text-dark-gray text-uppercase bg-base-color d-inline-block border-radius-30px ps-15px pe-15px ms-5px align-middle">
                                mnk25
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </section>
        {/* end section */}
    </>
}
