import React, { useEffect } from 'react'
import ServiceBenefits from './ServiceBenefits/ServiceBenefits'
import HomePageSlider from './HomePageSlider/HomePageSlider'
import Categories from './Categories/Categories'
import BestSellerProducts from './BestSellerProducts/BestSellerProducts'
import DiscountBanner from './DiscountBanner/DiscountBanner'
import Collections from './Collections/Collections'
import HomePageBrands from './HomePageBrands/HomePageBrands'
import ScrollingTexts from './ScrollingTexts/ScrollingTexts'

export default function HomePage() {
    useEffect(() => { try { _Html5Initializer() } catch (error) { console.log(error) } }, [])
    return <>
        <HomePageSlider />
        <ServiceBenefits />
        <Categories />
        <BestSellerProducts />

        <DiscountBanner />
        <Collections />
        <HomePageBrands />
        {/* start section */}
        <section className="ps-7 pe-7 pb-3 lg-ps-3 lg-pe-3 md-pb-5 xs-px-0">
            <div className="container">
                <div className="row mb-5 xs-mb-8">
                    <div className="col-12 text-center">
                        <h2 className="alt-font text-dark-gray mb-0 ls-minus-2px">
                            Featured{" "}
                            <span className="text-highlight fw-600">
                                products
                                <span className="bg-base-color h-5px bottom-2px" />
                            </span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <ul
                            className="shop-modern shop-wrapper grid-loading grid grid-5col lg-grid-3col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                            data-anime='{ "el": "childs", "translateY": [-15, 0], "opacity": [0,1], "duration": 300, "delay": 0, "staggervalue": 100, "easing": "easeOutQuad" }'
                        >
                            <li className="grid-sizer" />
                            {/* start shop item */}
                            <li className="grid-item">
                                <div className="shop-box mb-10px">
                                    <div className="shop-image mb-20px">
                                        <a href="demo-fashion-store-single-product.html">
                                            <img src="https://via.placeholder.com/600x765" alt="" />
                                            <span className="lable new">New</span>
                                            <div className="shop-overlay bg-gradient-gray-light-dark-transparent" />
                                        </a>
                                        <div className="shop-buttons-wrap">
                                            <a
                                                href="demo-fashion-store-single-product.html"
                                                className="alt-font btn btn-small btn-box-shadow btn-white btn-round-edge left-icon add-to-cart"
                                            >
                                                <i className="feather icon-feather-shopping-bag" />
                                                <span className="quick-view-text button-text">
                                                    Add to cart
                                                </span>
                                            </a>
                                        </div>
                                        <div className="shop-hover d-flex justify-content-center">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Add to wishlist"
                                                    >
                                                        <i className="feather icon-feather-heart fs-16" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Quick shop"
                                                    >
                                                        <i className="feather icon-feather-eye fs-16" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="shop-footer text-center">
                                        <a
                                            href="demo-fashion-store-single-product.html"
                                            className="alt-font text-dark-gray fs-19 fw-500"
                                        >
                                            Textured sweater
                                        </a>
                                        <div className="price lh-22 fs-16">
                                            <del>$200.00</del>$189.00
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* end shop item */}
                            {/* start shop item */}
                            <li className="grid-item">
                                <div className="shop-box mb-10px">
                                    <div className="shop-image mb-20px">
                                        <a href="demo-fashion-store-single-product.html">
                                            <img src="https://via.placeholder.com/600x765" alt="" />
                                            <div className="shop-overlay bg-gradient-gray-light-dark-transparent" />
                                        </a>
                                        <div className="shop-buttons-wrap">
                                            <a
                                                href="demo-fashion-store-single-product.html"
                                                className="alt-font btn btn-small btn-box-shadow btn-white btn-round-edge left-icon add-to-cart"
                                            >
                                                <i className="feather icon-feather-shopping-bag" />
                                                <span className="quick-view-text button-text">
                                                    Add to cart
                                                </span>
                                            </a>
                                        </div>
                                        <div className="shop-hover d-flex justify-content-center">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Add to wishlist"
                                                    >
                                                        <i className="feather icon-feather-heart fs-16" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Quick shop"
                                                    >
                                                        <i className="feather icon-feather-eye fs-16" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="shop-footer text-center">
                                        <a
                                            href="demo-fashion-store-single-product.html"
                                            className="alt-font text-dark-gray fs-19 fw-500"
                                        >
                                            Traveller shirt
                                        </a>
                                        <div className="price lh-22 fs-16">
                                            <del>$350.00</del>$289.00
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* end shop item */}
                            {/* start shop item */}
                            <li className="grid-item">
                                <div className="shop-box mb-10px">
                                    <div className="shop-image mb-20px">
                                        <a href="demo-fashion-store-single-product.html">
                                            <img src="https://via.placeholder.com/600x765" alt="" />
                                            <div className="shop-overlay bg-gradient-gray-light-dark-transparent" />
                                        </a>
                                        <div className="shop-buttons-wrap">
                                            <a
                                                href="demo-fashion-store-single-product.html"
                                                className="alt-font btn btn-small btn-box-shadow btn-white btn-round-edge left-icon add-to-cart"
                                            >
                                                <i className="feather icon-feather-shopping-bag" />
                                                <span className="quick-view-text button-text">
                                                    Add to cart
                                                </span>
                                            </a>
                                        </div>
                                        <div className="shop-hover d-flex justify-content-center">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Add to wishlist"
                                                    >
                                                        <i className="feather icon-feather-heart fs-16" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Quick shop"
                                                    >
                                                        <i className="feather icon-feather-eye fs-16" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="shop-footer text-center">
                                        <a
                                            href="demo-fashion-store-single-product.html"
                                            className="alt-font text-dark-gray fs-19 fw-500"
                                        >
                                            Crewneck sweatshirt
                                        </a>
                                        <div className="price lh-22 fs-16">
                                            <del>$220.00</del>$199.00
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* end shop item */}
                            {/* start shop item */}
                            <li className="grid-item">
                                <div className="shop-box mb-10px">
                                    <div className="shop-image mb-20px">
                                        <a href="demo-fashion-store-single-product.html">
                                            <img src="https://via.placeholder.com/600x765" alt="" />
                                            <div className="shop-overlay bg-gradient-gray-light-dark-transparent" />
                                        </a>
                                        <div className="shop-buttons-wrap">
                                            <a
                                                href="demo-fashion-store-single-product.html"
                                                className="alt-font btn btn-small btn-box-shadow btn-white btn-round-edge left-icon add-to-cart"
                                            >
                                                <i className="feather icon-feather-shopping-bag" />
                                                <span className="quick-view-text button-text">
                                                    Add to cart
                                                </span>
                                            </a>
                                        </div>
                                        <div className="shop-hover d-flex justify-content-center">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Add to wishlist"
                                                    >
                                                        <i className="feather icon-feather-heart fs-16" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Quick shop"
                                                    >
                                                        <i className="feather icon-feather-eye fs-16" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="shop-footer text-center">
                                        <a
                                            href="demo-fashion-store-single-product.html"
                                            className="alt-font text-dark-gray fs-19 fw-500"
                                        >
                                            Skinny trousers
                                        </a>
                                        <div className="price lh-22 fs-16">
                                            <del>$300.00</del>$259.00
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* end shop item */}
                            {/* start shop item */}
                            <li className="grid-item">
                                <div className="shop-box mb-10px">
                                    <div className="shop-image mb-20px">
                                        <a href="demo-fashion-store-single-product.html">
                                            <img src="https://via.placeholder.com/600x765" alt="" />
                                            <div className="shop-overlay bg-gradient-gray-light-dark-transparent" />
                                        </a>
                                        <div className="shop-buttons-wrap">
                                            <a
                                                href="demo-fashion-store-single-product.html"
                                                className="alt-font btn btn-small btn-box-shadow btn-white btn-round-edge left-icon add-to-cart"
                                            >
                                                <i className="feather icon-feather-shopping-bag" />
                                                <span className="quick-view-text button-text">
                                                    Add to cart
                                                </span>
                                            </a>
                                        </div>
                                        <div className="shop-hover d-flex justify-content-center">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Add to wishlist"
                                                    >
                                                        <i className="feather icon-feather-heart fs-16" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="w-40px h-40px bg-white text-dark-gray d-flex align-items-center justify-content-center rounded-circle ms-5px me-5px"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Quick shop"
                                                    >
                                                        <i className="feather icon-feather-eye fs-16" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="shop-footer text-center">
                                        <a
                                            href="demo-fashion-store-single-product.html"
                                            className="alt-font text-dark-gray fs-19 fw-500"
                                        >
                                            Sleeve sweater
                                        </a>
                                        <div className="price lh-22 fs-16">
                                            <del>$250.00</del>$239.00
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* end shop item */}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/* end section */}
        <ScrollingTexts />
        {/* start section */}
        <section className="pb-3 ps-7 pe-7 lg-ps-3 lg-pe-3 xs-px-0">
            <div className="container">
                <div className="row mb-4 xs-mb-7">
                    <div className="col-12 text-center">
                        <h2 className="alt-font text-dark-gray mb-0 ls-minus-2px">
                            Fashion{" "}
                            <span className="text-highlight fw-600">
                                magazine
                                <span className="bg-base-color h-5px bottom-2px" />
                            </span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <ul
                            className="blog-classic blog-wrapper grid-loading grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large"
                            data-anime='{ "el": "childs", "translateY": [15, 0], "translateX": [-15, 0], "opacity": [0,1], "duration": 500, "delay": 300, "staggervalue": 300, "easing": "easeOutQuad" }'
                        >
                            <li className="grid-sizer" />
                            {/* start blog item */}
                            <li className="grid-item">
                                <div className="card bg-transparent border-0 h-100">
                                    <div className="blog-image position-relative overflow-hidden">
                                        <a href="demo-fashion-store-blog-single-creative.html">
                                            <img src="https://via.placeholder.com/600x455" alt="" />
                                        </a>
                                    </div>
                                    <div className="card-body px-0 pt-30px pb-30px sm-pb-15px">
                                        <span className="mb-5px d-block">
                                            By{" "}
                                            <a
                                                href="#"
                                                className="text-dark-gray fw-500 categories-text"
                                            >
                                                Den viliamson
                                            </a>
                                            <a href="#" className="blog-date">
                                                26 December 2023
                                            </a>
                                        </span>
                                        <a
                                            href="demo-fashion-store-blog-single-creative.html"
                                            className="alt-font card-title fs-20 lh-30 fw-500 text-dark-gray d-inline-block w-75 xl-w-85 lg-w-100"
                                        >
                                            Elegance is not standing out, but being remembered.
                                        </a>
                                    </div>
                                </div>
                            </li>
                            {/* end blog item */}
                            {/* start blog item */}
                            <li className="grid-item">
                                <div className="card bg-transparent border-0 h-100">
                                    <div className="blog-image position-relative overflow-hidden">
                                        <a href="demo-fashion-store-blog-single-creative.html">
                                            <img src="https://via.placeholder.com/600x455" alt="" />
                                        </a>
                                    </div>
                                    <div className="card-body px-0 pt-30px pb-30px sm-pb-15px">
                                        <span className="mb-5px d-block">
                                            By{" "}
                                            <a
                                                href="#"
                                                className="text-dark-gray fw-500 categories-text"
                                            >
                                                Hugh macleod
                                            </a>
                                            <a href="#" className="blog-date">
                                                20 December 2023
                                            </a>
                                        </span>
                                        <a
                                            href="demo-fashion-store-blog-single-creative.html"
                                            className="alt-font card-title fs-20 lh-30 fw-500 text-dark-gray d-inline-block w-75 xl-w-85 lg-w-100"
                                        >
                                            Fashion is the armor to survive the reality of everyday
                                            life.
                                        </a>
                                    </div>
                                </div>
                            </li>
                            {/* end blog item */}
                            {/* start blog item */}
                            <li className="grid-item">
                                <div className="card bg-transparent border-0 h-100">
                                    <div className="blog-image position-relative overflow-hidden">
                                        <a href="demo-fashion-store-blog-single-creative.html">
                                            <img src="https://via.placeholder.com/600x455" alt="" />
                                        </a>
                                    </div>
                                    <div className="card-body px-0 pt-30px pb-30px sm-pb-15px">
                                        <span className="mb-5px d-block">
                                            By{" "}
                                            <a
                                                href="#"
                                                className="text-dark-gray fw-500 categories-text"
                                            >
                                                Walton smith
                                            </a>
                                            <a href="#" className="blog-date">
                                                10 December 2023
                                            </a>
                                        </span>
                                        <a
                                            href="demo-fashion-store-blog-single-creative.html"
                                            className="alt-font card-title fs-20 lh-30 fw-500 text-dark-gray d-inline-block w-75 xl-w-85 lg-w-100"
                                        >
                                            In order to be irreplaceable one must always be different.
                                        </a>
                                    </div>
                                </div>
                            </li>
                            {/* end blog item */}
                            {/* start blog item */}
                            <li className="grid-item">
                                <div className="card bg-transparent border-0 h-100">
                                    <div className="blog-image position-relative overflow-hidden">
                                        <a href="demo-fashion-store-blog-single-creative.html">
                                            <img src="https://via.placeholder.com/600x455" alt="" />
                                        </a>
                                    </div>
                                    <div className="card-body px-0 pt-30px pb-30px sm-pb-15px">
                                        <span className="mb-5px d-block">
                                            By{" "}
                                            <a
                                                href="#"
                                                className="text-dark-gray fw-500 categories-text"
                                            >
                                                Walton smith
                                            </a>
                                            <a href="#" className="blog-date">
                                                10 December 2023
                                            </a>
                                        </span>
                                        <a
                                            href="demo-fashion-store-blog-single-creative.html"
                                            className="alt-font card-title fs-20 lh-30 fw-500 text-dark-gray d-inline-block w-75 xl-w-85 lg-w-100"
                                        >
                                            Gucci has represented design and contemporary lifestyle.
                                        </a>
                                    </div>
                                </div>
                            </li>
                            {/* end blog item */}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/* end section */}
    </>
}
