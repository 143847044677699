import React from 'react'

export default function MnkFooter() {
    return <>
        {/* start footer */}
        <footer className="footer-dark bg-dark-gray p-0">
            <div className="container">
                <div className="row align-items-center pt-35px pb-35px">
                    {/* start footer column */}
                    <div className="col-12 col-md-auto sm-mb-15px text-center text-md-start">
                        <a href="demo-fashion-store.html" className="footer-logo">
                            <h3 style={{ margin: 0, color: "var(--base-color)" }}>Minikka</h3>
                            {/*<img
                                src="images/demo-fashion-store-logo-white.png"
                                data-at2x="images/demo-fashion-store-logo-white@2x.png"
                                alt=""
                                className="default-logo"
                            />*/}
                        </a>
                    </div>
                    {/* end footer column */}
                    {/* start footer column */}
                    <div className="col">
                        <ul className="footer-navbar text-center text-md-end">
                            <li className="nav-item">
                                <a href="demo-fashion-store.html" className="nav-link">
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="demo-fashion-store-shop.html" className="nav-link">
                                    Shop
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="demo-fashion-store-collection.html" className="nav-link">
                                    Collection
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="demo-fashion-store-magazine.html" className="nav-link">
                                    Magazine
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="demo-fashion-store-about.html" className="nav-link">
                                    About
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="demo-fashion-store-contact.html" className="nav-link">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* end footer column */}
                </div>
                <div className="row justify-content-center fs-15 lh-28 pb-50px xs-pb-35px">
                    <div className="col-12 mb-50px sm-mb-35px">
                        <div className="divider-style-03 divider-style-03-01 border-color-transparent-white-light" />
                    </div>
                    {/* start footer column */}
                    <div className="col-6 col-lg-2 col-sm-4 xs-mb-30px order-sm-3 order-lg-2">
                        <span className="fw-500 d-block text-white mb-5px fs-17">
                            Categories
                        </span>
                        <ul>
                            <li>
                                <a href="demo-fashion-store-shop.html">Men</a>
                            </li>
                            <li>
                                <a href="demo-fashion-store-shop.html">Women</a>
                            </li>
                            <li>
                                <a href="demo-fashion-store-shop.html">Accessories</a>
                            </li>
                            <li>
                                <a href="demo-fashion-store-shop.html">Shoes</a>
                            </li>
                            <li>
                                <a href="demo-fashion-store-shop.html">Dresses</a>
                            </li>
                        </ul>
                    </div>
                    {/* end footer column */}
                    {/* start footer column */}
                    <div className="col-6 col-lg-2 col-sm-4 xs-mb-30px order-sm-3 order-lg-2">
                        <span className="fw-500 d-block text-white mb-5px fs-17">
                            Information
                        </span>
                        <ul>
                            <li>
                                <a href="demo-fashion-store-about.html">About us</a>
                            </li>
                            <li>
                                <a href="demo-fashion-store-contact.html">Contact us</a>
                            </li>
                            <li>
                                <a href="#">Terms &amp; conditions</a>
                            </li>
                            <li>
                                <a href="#">Shipping &amp; delivery</a>
                            </li>
                            <li>
                                <a href="#">Privacy policy</a>
                            </li>
                        </ul>
                    </div>
                    {/* end footer column */}
                    {/* start footer column */}
                    <div className="col-6 col-lg-2 col-sm-4 xs-mb-30px order-sm-3 order-lg-2">
                        <span className="fw-500 d-block text-white mb-5px fs-17">
                            Quick links
                        </span>
                        <ul>
                            <li>
                                <a href="demo-fashion-store-account.html">My account</a>
                            </li>
                            <li>
                                <a href="#">Orders tracking</a>
                            </li>
                            <li>
                                <a href="#">Our store</a>
                            </li>
                            <li>
                                <a href="#">Size guide</a>
                            </li>
                            <li>
                                <a href="demo-fashion-store-faq.html">FAQs</a>
                            </li>
                        </ul>
                    </div>
                    {/* end footer column */}
                    {/* start footer column */}
                    <div className="col-6 col-lg-3 col-md-4 col-sm-5 md-mb-50px xs-mb-30px order-sm-2 order-lg-2 offset-md-2 offset-lg-0">
                        <span className="fw-500 d-block text-white mb-10px fs-17">
                            Quick contact
                        </span>
                        <div>
                            <i className="feather icon-feather-phone-call fs-16 text-white me-10px xs-me-5px" />
                            <a href="tel:1234567890">123 456 7890</a>
                        </div>
                        <div className="mb-15px">
                            <i className="feather icon-feather-mail fs-16 text-white me-10px xs-me-5px" />
                            <a
                                href="mailto:info@domain.com"
                                className="text-decoration-line-bottom"
                            >
                                info@domain.com
                            </a>
                        </div>
                        <span className="fw-500 d-block text-white mb-5px fs-17">
                            Connect with us
                        </span>
                        <div className="elements-social social-icon-style-02">
                            <ul className="light">
                                <li>
                                    <a
                                        className="facebook"
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                    >
                                        <i className="fa-brands fa-facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dribbble"
                                        href="http://www.dribbble.com"
                                        target="_blank"
                                    >
                                        <i className="fa-brands fa-dribbble" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="twitter"
                                        href="http://www.twitter.com"
                                        target="_blank"
                                    >
                                        <i className="fa-brands fa-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="instagram"
                                        href="http://www.instagram.com"
                                        target="_blank"
                                    >
                                        <i className="fa-brands fa-instagram" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* end footer column */}
                    {/* start footer column */}
                    <div className="col-lg-3 col-md-6 col-sm-7 ps-20px sm-ps-15px md-mb-50px xs-mb-0 order-sm-1 order-lg-5">
                        <span className="fw-500 d-block text-white mb-5px fs-17">
                            Become a member
                        </span>
                        <div className="mb-15px">Join now and get 20% extra discount!</div>
                        <div className="d-inline-block w-100 newsletter-style-04 position-relative mb-15px">
                            <form
                                action="email-templates/subscribe-newsletter.php"
                                method="post"
                                className="position-relative w-100"
                            >
                                <input
                                    className="input-small bg-nero-grey border-radius-4px fs-14 border-color-transparent w-100 form-control pe-50px ps-20px lg-ps-15px required"
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                />
                                <input type="hidden" name="redirect" defaultValue="" />
                                <button className="btn pe-20px submit" aria-label="submit">
                                    <i className="icon bi bi-envelope icon-small text-white" />
                                </button>
                                <div className="form-results border-radius-4px pt-5px pb-5px ps-15px pe-15px fs-14 lh-22 mt-10px w-100 text-center position-absolute d-none"></div>
                            </form>
                        </div>
                        <div className="footer-card">
                            <a href="#" className="d-inline-block me-5px align-middle">
                                <img src="https://via.placeholder.com/55x20" alt="" />
                            </a>
                            <a href="#" className="d-inline-block me-5px align-middle">
                                <img src="https://via.placeholder.com/55x20" alt="" />
                            </a>
                            <a href="#" className="d-inline-block me-5px align-middle">
                                <img src="https://via.placeholder.com/55x20" alt="" />
                            </a>
                            <a href="#" className="d-inline-block me-5px align-middle">
                                <img src="https://via.placeholder.com/55x20" alt="" />
                            </a>
                        </div>
                    </div>
                    {/* end footer column */}
                </div>
            </div>
            <div className="pt-30px pb-30px bg-nero-grey">
                <div className="container">
                    <div className="row align-items-center fs-15">
                        <div className="col-12 col-lg-7 last-paragraph-no-margin md-mb-15px text-center text-lg-start lh-22">
                            <p>
                                This site is protected by reCAPTCHA and the Google{" "}
                                <a href="#" className="text-white text-decoration-line-bottom">
                                    privacy policy
                                </a>{" "}
                                and{" "}
                                <a href="#" className="text-white text-decoration-line-bottom">
                                    terms of service.
                                </a>
                            </p>
                        </div>
                        <div className="col-12 col-lg-5 text-center text-lg-end lh-22">
                            <span>
                                © 2024 Crafto is Proudly Powered by{" "}
                                <a
                                    href="https://www.themezaa.com/"
                                    target="_blank"
                                    className="text-decoration-line-bottom text-white"
                                >
                                    ThemeZaa
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* end footer */}
    </>
}
