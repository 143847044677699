import React from 'react'

export default function ServiceBenefits() {
    return <>
        {/* start section */}
        <section className="half-section">
            <div className="container">
                <div
                    className="row row-cols-1 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-2"
                    data-anime='{ "el": "childs", "translateX": [30, 0], "opacity": [0,1], "duration": 800, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'
                >
                    {/* start features box item */}
                    <div className="col icon-with-text-style-01 md-mb-35px">
                        <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                            <div className="feature-box-icon me-20px">
                                <i className="line-icon-Box-Close icon-large text-dark-gray" />
                            </div>
                            <div className="feature-box-content">
                                <span className="alt-font fs-20 fw-500 d-block text-dark-gray">
                                    Ücretsiz Kargo
                                </span>
                                <p className="fs-16 lh-24">İlk alışverişinizde kargo ücretsiz</p>
                            </div>
                        </div>
                    </div>
                    {/* end features box item */}
                    {/* start features box item */}
                    <div className="col icon-with-text-style-01 md-mb-35px">
                        <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                            <div className="feature-box-icon me-20px">
                                <i className="line-icon-Reload-3 icon-large text-dark-gray" />
                            </div>
                            <div className="feature-box-content">
                                <span className="alt-font fs-20 fw-500 d-block text-dark-gray">
                                    15 günde iade
                                </span>
                                <p className="fs-16 lh-24">Para iade garantisi</p>
                            </div>
                        </div>
                    </div>
                    {/* end features box item */}
                    {/* start features box item */}
                    <div className="col icon-with-text-style-01 xs-mb-35px">
                        <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                            <div className="feature-box-icon me-20px">
                                <i className="line-icon-Credit-Card2 icon-large text-dark-gray" />
                            </div>
                            <div className="feature-box-content">
                                <span className="alt-font fs-20 fw-500 d-block text-dark-gray">
                                    Güvenli Ödeme
                                </span>
                                <p className="fs-16 lh-24">%100 Güvenli Ödeme</p>
                            </div>
                        </div>
                    </div>
                    {/* end features box item */}
                    {/* start features box item */}
                    <div className="col icon-with-text-style-01">
                        <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                            <div className="feature-box-icon me-20px">
                                <i className="line-icon-Phone-2 icon-large text-dark-gray" />
                            </div>
                            <div className="feature-box-content">
                                <span className="alt-font fs-20 fw-500 d-block text-dark-gray">
                                    Çevrimiçi destek
                                </span>
                                <p className="fs-16 lh-24">24/7 destek</p>
                            </div>
                        </div>
                    </div>
                    {/* end features box item */}
                </div>
            </div>
        </section>
        {/* end section */}
    </>
}
