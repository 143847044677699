import React, { useEffect, useState } from 'react'

export default function HomePageSlider() {
    return <>
        {/* start section */}
        <section className="p-0">
            <div
                className="swiper full-screen top-space-margin md-h-600px sm-h-500px magic-cursor magic-cursor-vertical swiper-number-pagination-progress swiper-number-pagination-progress-vertical"
                data-slider-options='{ "slidesPerView": 1, "direction": "horizontal", "loop": true, "parallax": true, "speed": 1000, "pagination": { "el": ".swiper-number", "clickable": true }, "autoplay": { "delay": 4000, "disableOnInteraction": false },  "keyboard": { "enabled": true, "onlyInViewport": true }, "breakpoints": { "1199": { "direction": "vertical" }}, "effect": "slide" }'
                data-swiper-number-pagination-progress="true"
            >
                <div className="swiper-wrapper">
                    {/* start slider item */}
                    <div className="swiper-slide overflow-hidden">
                        <div
                            className="cover-background position-absolute top-0 start-0 w-100 h-100"
                            data-swiper-parallax={500}
                            style={{ backgroundImage: 'url("images/minikka/slider3.jpg")' }}
                        >
                            <div className="container h-100">
                                <div className="row align-items-center h-100 justify-content-start">
                                    <div className="col-md-10 position-relative text-white d-flex flex-column justify-content-center h-100">
                                        <div
                                            data-anime='{ "opacity": [0, 1], "translateY": [50, 0], "easing": "easeOutQuad", "duration": 500, "delay": 300 }'
                                            className="alt-font text-dark-gray mb-25px fs-20 sm-mb-15px"
                                        >
                                            <span className="text-highlight">
                                                Discount on selected collection!
                                                <span className="bg-base-color h-8px bottom-0px" />
                                            </span>
                                        </div>
                                        <div
                                            className="alt-font fs-120 xs-fs-95 lh-100 mb-40px text-dark-gray fw-600 transform-origin-right ls-minus-5px sm-mb-25px"
                                            data-anime='{ "el": "childs", "rotateX": [90, 0], "opacity": [0,1], "staggervalue": 150, "easing": "easeOutQuad" }'
                                        >
                                            <span className="d-block">Women's</span>
                                            <span className="d-block fw-300">collection</span>
                                        </div>
                                        <div data-anime='{ "opacity": [0, 1], "translateY": [100, 0], "easing": "easeOutQuad", "duration": 800, "delay": 400 }'>
                                            <a
                                                href="demo-fashion-store-shop.html"
                                                className="btn btn-dark-gray btn-box-shadow btn-large"
                                            >
                                                View collection
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end slider item */}
                    {/* start slider item */}
                    <div className="swiper-slide overflow-hidden">
                        <div
                            className="cover-background position-absolute top-0 start-0 w-100 h-100"
                            data-swiper-parallax={500}
                            style={{ backgroundImage: 'url("images/minikka/slider2.jpg")' }}
                        >
                            <div className="container h-100">
                                <div className="row align-items-center h-100 justify-content-start">
                                    <div className="col-md-10 position-relative text-white d-flex flex-column justify-content-center h-100">
                                        <div
                                            data-anime='{ "opacity": [0, 1], "translateY": [50, 0], "easing": "easeOutQuad", "duration": 500, "delay": 300 }'
                                            className="alt-font text-dark-gray mb-25px fs-20 sm-mb-15px"
                                        >
                                            <span className="text-highlight">
                                                Discount on selected collection!
                                                <span className="bg-base-color h-8px bottom-0px" />
                                            </span>
                                        </div>
                                        <div
                                            className="alt-font fs-120 xs-fs-95 lh-100 mb-40px text-dark-gray fw-600 transform-origin-right ls-minus-5px sm-mb-25px"
                                            data-anime='{ "el": "childs", "rotateX": [90, 0], "opacity": [0,1], "staggervalue": 150, "easing": "easeOutQuad" }'
                                        >
                                            <span className="d-block">Men's</span>
                                            <span className="d-block fw-300">collection</span>
                                        </div>
                                        <div data-anime='{ "opacity": [0, 1], "translateY": [100, 0], "easing": "easeOutQuad", "duration": 800, "delay": 400 }'>
                                            <a
                                                href="demo-fashion-store-shop.html"
                                                className="btn btn-dark-gray btn-box-shadow btn-large"
                                            >
                                                View collection
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end slider item */}
                    {/* start slider item */}
                    <div className="swiper-slide overflow-hidden">
                        <div
                            className="cover-background position-absolute top-0 start-0 w-100 h-100"
                            data-swiper-parallax={500}
                            style={{ backgroundImage: 'url("images/minikka/slider1.jpg")' }}
                        >
                            <div className="container h-100">
                                <div className="row align-items-center h-100 justify-content-start">
                                    <div className="col-md-10 position-relative text-white d-flex flex-column justify-content-center h-100">
                                        <div
                                            data-anime='{ "opacity": [0, 1], "translateY": [50, 0], "easing": "easeOutQuad", "duration": 500, "delay": 300 }'
                                            className="alt-font text-dark-gray mb-25px fs-20 sm-mb-15px"
                                        >
                                            <span className="text-highlight">
                                                Discount on selected collection!
                                                <span className="bg-base-color h-8px bottom-0px" />
                                            </span>
                                        </div>
                                        <div
                                            className="alt-font fs-120 xs-fs-95 lh-100 mb-40px text-dark-gray fw-600 transform-origin-right ls-minus-5px sm-mb-25px"
                                            data-anime='{ "el": "childs", "rotateX": [90, 0], "opacity": [0,1], "staggervalue": 150, "easing": "easeOutQuad" }'
                                        >
                                            <span className="d-block">Children's</span>
                                            <span className="d-block fw-300">collection</span>
                                        </div>
                                        <div data-anime='{ "opacity": [0, 1], "translateY": [100, 0], "easing": "easeOutQuad", "duration": 800, "delay": 400 }'>
                                            <a
                                                href="demo-fashion-store-shop.html"
                                                className="btn btn-dark-gray btn-box-shadow btn-large"
                                            >
                                                View collection
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end slider item */}
                </div>
                {/* start slider pagination */}
                <div className="swiper-pagination-wrapper">
                    <div className="pagination-progress-vertical d-flex align-items-center justify-content-center">
                        <div className="number-prev text-dark-gray fs-16 fw-500" />
                        <div className="swiper-pagination-progress">
                            <span className="swiper-progress" />
                        </div>
                        <div className="number-next text-dark-gray fs-16 fw-500" />
                    </div>
                </div>
                {/* end slider pagination */}
            </div>
        </section>
        {/* end section */}
    </>
}
