import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MnkFooter from './Common/Footer/MnkFooter';
import MnkHeader from './Common/Header/MnkHeader';
import HomePage from './HomePage/HomePage';

function App() {
  return <>
    <BrowserRouter>
      <MnkHeader />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<div style={{ minHeight: "85vh" }}><h1>404 not found!</h1></div>} />
      </Routes>

      <MnkFooter />
    </BrowserRouter>
  </>
}

export default App;
