import React from 'react'

export default function Collections() {
    return <>
        {/* start section */}
        <section className="bg-very-light-gray overflow-hidden position-relative ps-3 xs-ps-0">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-3 ps-5 pe-5 xl-pe-0 lg-ps-0 text-center text-lg-start md-mb-40px">
                        <div className="mb-10px">
                            <span className="text-dark-gray fw-500 text-highlight">
                                2024 kreasyonu
                                <span className="bg-base-color h-8px bottom-0px" />
                            </span>
                        </div>
                        <h2 className="alt-font lh-50 text-dark-gray ls-minus-1px mb-15px">
                            Yeni gelen <span className="fw-600">Koleksiyon</span>
                        </h2>
                        <p className="xs-pe-15px xs-ps-15px">
                            Seçili erkek koleksiyonunda %70'e varan yazlık indirim.
                        </p>
                        <a
                            href="demo-fashion-store-shop.html"
                            className="btn btn-dark-gray btn-box-shadow btn-medium"
                        >
                            Koleksiyonu görüntüle
                        </a>
                    </div>
                    <div className="col-12 col-lg-9 position-relative">
                        <div className="outside-box-right-10 lg-outside-box-right-20 md-outside-box-right-25 xs-outside-box-right-0">
                            <div
                                className="swiper slider-three-slide"
                                data-slider-options='{ "slidesPerView": 1, "spaceBetween": 30, "loop": true, "autoplay": { "delay": 4000, "disableOnInteraction": false }, "pagination": { "el": ".slider-four-slide-pagination-1", "clickable": true, "dynamicBullets": false }, "keyboard": { "enabled": true, "onlyInViewport": true }, "breakpoints": { "1400": { "slidesPerView": 4 }, "1024": { "slidesPerView": 3 }, "768": { "slidesPerView": 3 }, "576": { "slidesPerView": 2 }, "320": { "slidesPerView": 1 } }, "effect": "slide" }'
                            >
                                <div className="swiper-wrapper">
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Ethnic wear
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Outfits matching
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Dress materials
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Explore a variety
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Western wear
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Traditional attires
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Loungewear
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Women branded
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Ethnic wear
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Outfits matching
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Dress materials
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Explore a variety
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Western wear
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Traditional attires
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                    {/* start content carousal item */}
                                    <div className="swiper-slide">
                                        <div className="interactive-banner-style-09 border-radius-6px overflow-hidden position-relative">
                                            <img src="https://via.placeholder.com/600x730" alt="" />
                                            <div className="opacity-full bg-gradient-gray-light-dark-transparent" />
                                            <div className="image-content h-100 w-100 ps-15 pe-15 pt-11 pb-11 lg-p-11 d-flex justify-content-bottom align-items-start flex-column">
                                                <div className="mt-auto d-flex align-items-start w-100 z-index-1 position-relative overflow-hidden flex-column">
                                                    <span className="text-white fw-500 fs-22">
                                                        Loungewear
                                                    </span>
                                                    <span className="content-title text-white fs-14 fw-500 opacity-7 text-uppercase ls-05px">
                                                        Women branded
                                                    </span>
                                                    <a
                                                        href="demo-fashion-store-shop.html"
                                                        className="content-title-hover fs-14 lh-24 fw-500 ls-05px text-uppercase text-white opacity-6 text-decoration-line-bottom"
                                                    >
                                                        Explore collection
                                                    </a>
                                                    <span className="content-arrow lh-50 rounded-circle bg-base-color w-50px h-50px ms-20px text-center">
                                                        <i className="bi bi-arrow-right-short text-dark-gray icon-very-medium" />
                                                    </span>
                                                </div>
                                                <div className="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                                                <div className="box-overlay bg-gradient-gray-light-dark-transparent" />
                                                <a
                                                    href="demo-fashion-store-shop.html"
                                                    className="position-absolute z-index-1 top-0px left-0px h-100 w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end content carousal item */}
                                </div>
                            </div>
                        </div>
                        {/* start slider pagination */}
                        {/*<div class="swiper-pagination slider-four-slide-pagination-1 swiper-pagination-style-2 swiper-pagination-clickable swiper-pagination-bullets"></div>*/}
                        {/* end slider pagination */}
                    </div>
                </div>
            </div>
            <div
                className="fs-180 lg-fs-150 md-fs-130 fw-700 position-absolute bottom-minus-50px md-bottom-minus-40px ls-minus-5px left-0px right-0px text-center w-100 opacity-1 d-none d-md-block"
                data-bottom-top="transform:scale(1, 1) translate3d(0px, 0px, 0px);"
                data-top-bottom="transform:scale(1, 1) translate3d(-100px, 0px, 0px);"
            >
                new collection
            </div>
        </section>
        {/* end section */}
    </>
}
