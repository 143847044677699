import React from 'react'

export default function SingleCategory({ categoryInfo }) {
    return <>
        {/* start categories style */}
        <div className="col categories-style-02">
            <div className="categories-box">
                <a href="demo-fashion-store-shop.html">
                    <img
                        className="sm-w-100"
                        src={categoryInfo?.picture || "https://via.placeholder.com/600x450"}
                        alt=""
                    />
                </a>
                <div className="border-color-transparent-dark-very-light border alt-font fw-500 text-dark-gray text-uppercase ps-15px pe-15px fs-11 lh-26 border-radius-100px d-inline-block position-absolute right-20px top-20px">
                    {categoryInfo?.count} ürün
                </div>
                <div className="absolute-bottom-center bottom-40px md-bottom-25px">
                    <a
                        href="demo-fashion-store-shop.html"
                        className="btn btn-white btn-switch-text btn-round-edge btn-box-shadow fs-18 text-uppercase-inherit p-5 min-w-150px"
                    >
                        <span>
                            <span className="btn-double-text ls-0px" data-text={categoryInfo?.title}>
                                {categoryInfo?.title}
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        {/* end categories style */}
    </>
}
