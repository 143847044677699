import React from 'react'
import SingleCategory from './SingleCategory'

export default function Categories() {
    const categoryInfos = [
        { picture: "https://via.placeholder.com/600x450", count: 12, title: "Spor Ayakkabı", url: "cocuk-ayakkabi-spor.html" },
        { picture: "https://via.placeholder.com/600x450", count: 15, title: "Sandalet", url: "cocuk-ayakkabi-sandalet.html" },
        { picture: "https://via.placeholder.com/600x450", count: 10, title: "Bot", url: "cocuk-ayakkabi-bot.html" },
        { picture: "https://via.placeholder.com/600x450", count: 8, title: "Resmi Ayakkabı", url: "cocuk-ayakkabi-resmi.html" }

        // { picture: "https://via.placeholder.com/600x450", count: 8, title: "Women", url: "demo-fashion-store-shop.html" },
        // { picture: "https://via.placeholder.com/600x450", count: 9, title: "Men", url: "demo-fashion-store-shop.html" },
        // { picture: "https://via.placeholder.com/600x450", count: 8, title: "Accessories", url: "demo-fashion-store-shop.html" },
        // { picture: "https://via.placeholder.com/600x450", count: 8, title: "Kids", url: "demo-fashion-store-shop.html" },
        // { title: "Niggas", picture: undefined, url: "/", count: 31 }
    ]
    return <>
        {/* start section */}
        <section className="pt-0 pb-0 ps-7 pe-7 lg-ps-3 lg-pe-3 xs-p-0">
            <div className="container-fluid">
                <div
                    className="row row-cols-1 row-cols-xl-4 row-cols-lg-2 row-cols-md-2"
                    data-anime='{ "el": "childs", "translateY": [-15, 0], "perspective": [1200,1200], "scale": [1.1, 1], "rotateX": [50, 0], "opacity": [0,1], "duration": 400, "delay": 100, "staggervalue": 200, "easing": "easeOutQuad" }'
                    style={{ rowGap: '30px' }}
                >
                    {categoryInfos.map(info => <SingleCategory categoryInfo={info} />)}
                </div>
            </div>
        </section>
        {/* end section */}
    </>
}
